<template>
    <a-card title="选择接稿文章类别" :bordered="false">
        <div class="category-list">
            <div class="category-list__item" :class="{'category-list__item--active': categoryPrimary(item.id) >= 0}" v-for="item in category" :key="item.id" @click="categoryChange(item)">{{item.title}}<a-icon class="category-list__icon" type="check" v-if="categoryPrimary(item.id) >= 0" /></div>
        </div>
        <a-button size="large" type="primary" class="submit" :loading="loading" @click="categoryUpdate">保存</a-button>
    </a-card>
</template>

<script>
import { mapState } from "vuex";
export default {
    data() {
        return {
            loading: false,
            category: [],
            userCategory: [],
        }
    },
    computed: {
		...mapState({
			userInfo: (state) => state.user.user_info,
		}),
    },
    created() {
        // 获取全部类别分类
		this.$store.dispatch("mart/getCategory").then(res => {
            this.category = res;
        });
        // 获取用户类别分类
		this.$store.dispatch("user/getCategory").then(res => {
            this.userCategory = res;
        });
    },
    methods: {
        categoryPrimary(id) {
            const userCategory = this.userCategory;
            for(let i = 0, max = userCategory.length; i< max; i++) {
                if(userCategory[i].catid == id) {
                    return i;
                }
            }
            return -1;
        },
        // 类别选择切换
        categoryChange(item) {
            const index = this.categoryPrimary(item.id);
            const max = this.userInfo.is_inside == 1 ? 100 : 3;
            if(index >= 0) {
                this.userCategory.splice(index, 1);
            }else if(this.userCategory.length < max){
                // 最大只能3项
                this.userCategory.push({
                    catid: item.id,
                    title: item.title
                });
            }else{
                this.$message.warn({content: `最多只能选择${max}个类别`,  key: 'messageKey'})
            }
        },
        // 上报
        categoryUpdate() {
            const userCategory = this.userCategory;
            let selectArr = [];
            for(let i = 0, max = userCategory.length; i < max; i++){
                selectArr.push(userCategory[i].catid);
            }
            if(selectArr.length == 0) {
                this.$message.warn({content: `请选择类别`,  key: 'messageKey'})
                return;
            }
            this.loading = true;
            this.$api.userManuscriptsCatidUpdate({catids: selectArr}).then(res => {
                this.loading = false;
                if(res.status) {
                    this.$message.success({content: '修改成功',  key: 'messageKey'})
                    // 触发更新
                    this.$store.dispatch("user/getCategory")
                }else{
                    this.$message.warn({content: res.msg,  key: 'messageKey'})
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
.category-list{
    max-width: 600px;
    padding: 0 0 40px;
    &__item{
        padding: 8px 24px;
        border-radius: 4px;
        display: inline-block;
        border: 1px solid #d8d8d8;
        margin: 0 12px 12px 0;
        position: relative;
        &--active{
            color: @color-red
        }
        &:hover{
            cursor: pointer;
        }
    }
    &__icon{
        position: absolute;
        font-size: 24px;
        bottom: -2px;
        right: -6px;
        font-weight: 700;
        color: @color-red;
    }
}
</style>